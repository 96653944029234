import { graphql } from "gatsby";
import React from "react";
import Container from "~components/common/Container";
import Divider from "~components/common/Divider";
import Grid from "~components/common/Grid";
import Layout from "~components/layouts/Layout";
import H1 from "~components/typography/H1";
import NumberedSection from "~components/typography/NumberedSection";
import P from "~components/typography/P";

export default function Page({ data, location }) {
	return (
		<Layout
			location={location}
			title="Anfrage gesendet"
			desc="Vielen Dank für Deine Anfrage. Wir werden uns zeitnah bei Dir melden."
			imageFacebook={`${data.site.siteMetadata.siteUrl}${data.seoImage.facebook.gatsbyImageData.images.fallback.src}`}
			imageFacebookWidth={data.seoImage.facebook.gatsbyImageData.width}
			imageFacebookHeight={data.seoImage.facebook.gatsbyImageData.height}
			imageTwitter={`${data.site.siteMetadata.siteUrl}${data.seoImage.twitter.gatsbyImageData.images.fallback.src}`}
			noIndex
		>
			<Container noPadding className="py-6">
				<Divider className="mb-4" />
				<NumberedSection text="Deine Mail ist angekommen" number="01" className="mb-5" />
				<H1 display className="mb-4 font-display">
					Anfrage gesendet
				</H1>
				<Grid cols={2}>
					<div>
						<P className="mb-5">Vielen Dank für Deine Anfrage. Wir werden uns zeitnah bei Dir melden.</P>
					</div>
				</Grid>
			</Container>
		</Layout>
	);
}

export const query = graphql`
	query {
		seoImage: file(relativePath: { eq: "pages/anfrage-gesendet/anfrage-gesendet_seo-image.jpg" }) {
			facebook: childImageSharp {
				gatsbyImageData(height: 630, width: 1200, formats: JPG, quality: 100)
			}
			twitter: childImageSharp {
				gatsbyImageData(aspectRatio: 1, formats: JPG, quality: 100)
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
